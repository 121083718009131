import React, { Component } from 'react'

import Media from 'react-media'
import PlainLink from '../PlainLink'
// import BurgerMenu from './BurgerMenu'
import DesktopHeader from './DesktopHeader'
import Logo from './Logo'
import { Flex, Box } from '@rebass/grid/emotion'
import { Text } from '@rebass/emotion'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Link from 'gatsby-link'

import Modal from 'react-modal';
Modal.defaultStyles.overlay.zIndex = '10';

const customStyles = {
    content: {
        top: '85px',
        left: 0,
        right: 0,
        bottom: 0,
    }
};

const MenuLink = css`
    display: block;
    color: #000;
    font-size: 2rem;
    margin: 15px 0;
    text-decoration: none;
`

const Wrapper = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1000;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
`

const InnerWrapper = css`
    height: 8.5rem;
    width: 90%;
    max-width: 1280px;
    margin: 0 auto;
`

const Button = styled.button`
  padding: 0;
  margin: 0;

  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;


  color: inherit;
  font: inherit;

  line-height: normal;

  outline: 0;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;


  -webkit-appearance: none;

`


export default class extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sticky: false,
            modalIsOpen: false
        }
    }

    onOpenMenu = () => {
        this.setState({
            modalIsOpen: !this.state.modalIsOpen
        })
    }

    componentDidMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { inverted } = this.props
        const { sticky, modalIsOpen } = this.state


        return (
            <Flex sticky={sticky || inverted || modalIsOpen} css={[Wrapper, {
                backgroundColor: !(sticky || inverted || modalIsOpen) ? 'transparent' : 'rgba(255, 255, 255, 0.9)',
                // transform: !sticky ? 'translate3d(0,0,0)' : 'translate3d(0,-20%, 0)',
            }]}>
                <Flex css={InnerWrapper} justifyContent="space-between" alignItems="center">
                    <PlainLink to="/">
                        <Logo inverted={inverted || sticky} minimize={sticky} />
                    </PlainLink>

                    <Box>
                        <Media query="(min-width: 56em)">
                            {(matches) =>
                                matches ?
                                    <DesktopHeader
                                        leaveHeaderSearch={this.props.leaveHeaderSearch}
                                        onSearchChange={this.props.onSearchChange}
                                        inverted={inverted || sticky}
                                        minimize={sticky}
                                    />
                                    :
                                    <span onClick={this.onOpenMenu}>
                                        <Button>
                                            {!modalIsOpen ?
                                                <svg height="23" width="40">
                                                    <line stroke="#333" strokeWidth="2" x1="0" y1="2" x2="40" y2="2" />
                                                    <line stroke="#333" strokeWidth="2" x1="0" y1="12" x2="40" y2="12" />
                                                    <line stroke="#333" strokeWidth="2" x1="0" y1="22" x2="40" y2="22" />
                                                </svg>
                                                :
                                                <svg height="23" width="40">
                                                    <line stroke="#333" strokeWidth="2" x1="10" y1="2" x2="30" y2="22" />
                                                    <line stroke="#333" strokeWidth="2" x1="10" y1="22" x2="30" y2="2" />
                                                </svg>
                                            }
                                        </Button>
                                    </span>
                            }
                        </Media>
                    </Box>
                </Flex>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Menu"
                >
                    <Flex flexDirection="column" justifyContent="center" alignItems="center">
                        <Link css={MenuLink} to="/leistungen/"><Text fontSize={[24, 32]}>Leistungen</Text></Link>
                        <Link css={MenuLink} to="/ausstattung/"><Text fontSize={[24, 32]}>Ausstattung</Text></Link>
                        <Link css={MenuLink} to="/referenzen/"><Text fontSize={[24, 32]}>Referenzen</Text></Link>
                        <Link css={MenuLink} to="/unternehmen/"><Text fontSize={[24, 32]}>Unternehmen</Text></Link>
                        <Link css={MenuLink} to="/kontakt/"><Text fontSize={[24, 32]}>Kontakt</Text></Link>
                    </Flex>
                </Modal>
            </Flex>
        )
    }

    componentDidMount_() {
        window.window.addEventListener('scroll', () => {
            if (window.scrollY > 10) {
                if (!this.state.sticky) {
                    this.setState({
                        sticky: true,
                    })
                }
            } else {
                if (this.state.sticky) {
                    this.setState({
                        sticky: false,
                    })
                }
            }
        })
    }
}
