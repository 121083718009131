import styled from '@emotion/styled'

import { HeadlineSubPage, SubHeadlineFont } from '../../style/fonts'

export default styled('main')`
  min-height: 100vh;
`

export const Header = styled('header')`
  display: block;
  position: relative;
  width: 100%;
  height: 36vh;
  background-color: rgba(0, 0, 0, 0.3);

  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 10;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -25%, 0);
    z-index: 1;
  }

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: block;
    z-index: 20;

    > :nth-child(1) {
      text-align: center;
      color: #fff;

      ${SubHeadlineFont};
      margin: 0;
      padding: 0;
    }

    > :nth-child(2) {
      text-align: center;
      color: #fff;
      ${HeadlineSubPage};

      margin: 1.5rem 0;
      padding: 0;
    }
  }
`
