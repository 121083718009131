import React, { Component } from 'react'

import { InputWrapper } from './style'
import debounce from 'lodash/debounce'

export default class extends Component {
    state = {
        focused: false,
        value: ''
    }

    _refNode = (node) => (this._node = node)

    onSearchChange = debounce(() => {
        this.props.onSearchChange(this.state.value)
    }, 500)

    unsafe_componentWillReceiveProps(nextProps) {
        if (nextProps.hasErrors !== this.props.hasErrors) {
            this._node.setCustomValidity(
                nextProps.hasErrors ? this.props.errorMessage : ''
            )
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.leaveHeaderSearch !== this.props.leaveHeaderSearch && this.props.leaveHeaderSearch) {
            this.setState({
                value: ''
            })
        }
    }

    _onFocus = () => {
        this.setState({
            focused: true,
        })
    }

    _onBlur = () => {
        this.setState({
            focused: false,
        })
    }

    onChange = (ev) => {
        this.setState({
            value: ev.target.value
        }, () => {
            this.onSearchChange()
        })
    }

    render() {
        const { placeholder, inverted } = this.props
        const { focused, value } = this.state
        return (
            <InputWrapper inverted={inverted} focused={!!value || focused}>
                {!value && !focused && <label htmlFor="name">{placeholder}</label>}
                <input
                    value={this.state.value}
                    onChange={this.onChange}
                    onFocus={this._onFocus}
                    onBlur={this._onBlur}
                // onKeyUp={restProps.onChange}
                // ref={this._refNode}
                // {...restProps}
                />
                {/*<span>
                    <span>
                        <button>
                            <svg version="1.1" x="0px" y="0px" viewBox="0 0 451 451">
                                <path d="M447.05,428l-109.6-109.6c29.4-33.8,47.2-77.9,47.2-126.1C384.65,86.2,298.35,0,192.35,0C86.25,0,0.05,86.3,0.05,192.3 s86.3,192.3,192.3,192.3c48.2,0,92.3-17.8,126.1-47.2L428.05,447c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4 C452.25,441.8,452.25,433.2,447.05,428z M26.95,192.3c0-91.2,74.2-165.3,165.3-165.3c91.2,0,165.3,74.2,165.3,165.3 s-74.1,165.4-165.3,165.4C101.15,357.7,26.95,283.5,26.95,192.3z" />
                            </svg>
                        </button>
                    </span>
                </span>
                */}
            </InputWrapper>
        )
    }
}
