import React, { Component } from 'react'

import Link from 'gatsby-link'

import { DesktopNavigation } from './style'

export default class extends Component {
    render() {
        const { inverted } = this.props
        return (
            <DesktopNavigation inverted={inverted}>
                <Link to="/leistungen/">Leistungen</Link>
                <Link to="/ausstattung/">Ausstattung</Link>
                <Link to="/referenzen/">Referenzen</Link>
                <Link to="/unternehmen/">Unternehmen</Link>
                <Link to="/kontakt/">Kontakt</Link>
            </DesktopNavigation>
        )
    }
}
