import React, { Component } from 'react'

import SubPage from '../subPage'

import { Flex, Box } from '@rebass/grid/emotion'
import { Heading, Text, Card } from '@rebass/emotion'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import debounce from 'lodash/debounce'
import { Input } from '@rebass/forms'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const c = css`
    font-weight: 400;
    line-height: 2em;
    hyphens: auto;
    width: 90%;
    max-width: 70rem;
    margin: 2.5rem auto 0 auto;

    em {
        font-style: normal;
        font-weight: 400;
        hyphens: manual;
        color: #4975ba;
        border-bottom: 1px solid #4975ba;
    }
`

const InputBox = css`
    font-size: 14px;
`

class Referenzen extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            focused: false,
            value: props.term,
        }
    }

    onSearchChange = debounce(() => {
        this.props.onSearchChange(this.state.value)
    }, 500)

    onChange = (ev) => {
        this.setState({
            value: ev.target.value
        }, () => {
            this.onSearchChange()
        })
    }

    componentDidMount() {
        if (this.props.search && this.props.term) {
            this.myRef.current.focus()
            this.props.onLeaveHeaderSearch()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.search && this.props.term) {
            if (prevProps.term !== this.props.term) {
                this.myRef.current.focus()
                this.props.onLeaveHeaderSearch()
            }
        }
    }

    render() {
        const { search } = this.props

        const edges = this.props.data.allProject.edges

        return (
            <SubPage>
                <Box py={[20, 0]} mb={[50, 100]}>
                    {!search && <Flex justifyContent="center">
                        <Box width={1}>
                            <Text color="#6f7c82" fontSize={[18, 18, 18, 20]} fontWeight={600} textAlign="center">Unsere Projekte</Text>
                            <Heading color="#292e31" fontSize={[30, 42, 46, 60]} fontWeight={600} textAlign="center">Referenzen</Heading>
                            <Text css={c} color="#32335c" fontWeight={400} fontSize={[16, 16, 18, 22]} lineHeight={40} textAlign="center">
                                Alles aus einer Hand. Planung, Produktion und Montage
                            </Text>
                        </Box>
                    </Flex>}
                    {search && <Flex justifyContent="center">
                        <Box width={1 / 1.5}>
                            <Box width={[1, 1, 1 / 2]} m="auto">
                                <Input css={InputBox} onChange={this.onChange} value={this.state.value} ref={this.myRef} type="text" />
                            </Box>
                            <Text css={c} color="#32335c" fontWeight={400} fontSize={[16, 16, 18, 22]} lineHeight={40} textAlign="center">
                                Die <em>Planung</em> und Entwicklung der Balkone sind ebenso zu
                                unserem.
                            </Text>
                        </Box>
                    </Flex>}
                    <Box mt={[20, 50]} py={[20, 50]} css={{
                        backgroundColor: '#F3F5F6',
                    }}>
                        <Box m='auto' width={[0.9, 0.9, 0.9, 1]} css={{
                            maxWidth: '1250px',
                            zIndex: 1,
                            position: 'relative'
                        }}>
                            <Flex flexWrap='wrap'>
                                {edges.map(({ node }) =>
                                    <Box key={node.id} width={[1 / 1, 1 / 2, 1 / 3]}>
                                        <Card mx={[0, 10]} my={15}
                                            borderRadius={5}>

                                            <Link to={node.path} css={{ textDecoration: 'none' }}>
                                                <Box css={[{ overflow: 'hidden', borderRadius: 5 }]}>
                                                    <Box css={{
                                                        position: 'relative',
                                                        height: 0,
                                                        paddingBottom: '65%',
                                                        overflow: 'hidden'
                                                    }}>

                                                        {node && node.teaser && <GatsbyImage image={getImage(node.teaser.file)} alt={node.projekt + " " + node.beschreibung} />}
                                                    </Box>
                                                    <Box py={[20, 20, 20, 30]} px={30} css={css`
                                                        background-color: #fff;

                                                        @media (min-width: 32em) {
                                                            min-height: 200px;
                                                        }

                                                        @media (min-width: 56em) {
                                                            min-height: 240px;
                                                        }

                                                        @media (min-width: 74em) {
                                                            min-height: 280px;
                                                        }
                                                    
                                                    `}>
                                                        <Heading
                                                            mt={0}
                                                            mb={[10, 10, 10, 20]}
                                                            color="#064360"
                                                            fontWeight={400}
                                                            fontSize={[20, 20, 22, 30]}>
                                                            {node.titelLagebeschreibung}
                                                        </Heading>
                                                        <Text fontSize={16} lineHeight={1.8} color="#064360">
                                                            <div>{node.projekt}</div>
                                                            <div>{node.beschreibung}</div>
                                                        </Text>

                                                        <Box mt={10}>
                                                            <Text color="#555" fontWeight={400} lineHeight="1.6em" fontSize={[10, 10, 12, 14]} textAlign="left">
                                                                {['konstruktionsVariante', 'gelander', 'beschichtung', 'entwasserungebene', 'fussboden']
                                                                    .filter(key => node[key])
                                                                    .map((key, idx, list) =>
                                                                        <span key={key}>{node[key]}{idx < (list.length - 2) ? ' - ' : ''}</span>
                                                                    )}
                                                            </Text>
                                                        </Box>

                                                    </Box>
                                                </Box>
                                            </Link>

                                        </Card>
                                    </Box>
                                )}
                            </Flex>
                        </Box>
                    </Box>
                </Box>
            </SubPage >
        )
    }
}

export default Referenzen
