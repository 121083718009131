import React, { Component } from 'react'

import { Header } from './style'
import { Box } from '@rebass/grid/emotion'
export default class extends Component {
    render() {
        return <Box mt={[25, 100]}>{this.props.children}</Box>
    }
}

export class SubPageHeader extends Component {
    render() {
        return (
            <Header>
                <img src={this.props.backgroundImage} />
                <div>{this.props.children}</div>
            </Header>
        )
    }
}
