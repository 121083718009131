import React, { Component } from 'react'

import Input from './input'
import Menu from './Menu'
import { DesktopHeader } from './style'

export default class extends Component {
    render() {
        const { inverted, minimize } = this.props
        return (
            <DesktopHeader minimize={minimize}>
                <Menu inverted={inverted} />
                <Input leaveHeaderSearch={this.props.leaveHeaderSearch} onSearchChange={this.props.onSearchChange} placeholder="Suche" inverted={inverted} value="" />
            </DesktopHeader>
        )
    }
}
