import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BaseList = css`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const BaseEnum = css`
    padding: 0;
    margin: 0;
    list-style: none;
`;

export const SubHeadlineFont = css`
    font-style: normal;
    line-height: 1.3em;
    letter-spacing: 0.01em;
    font-weight: 600;
    font-size: 1.8rem;
`;

export const HeadlineFont = css`
    font-style: normal;
    line-height: 1.3em;
    letter-spacing: 0.01em;
    font-weight: 600;
    font-size: 2.8rem;
`;

export const HeadlineBullet = css`
    font-style: normal;
    line-height: 1.3em;
    letter-spacing: 0.01em;
    font-weight: 600;
    font-size: 2.15rem;
    color: #ffc914;
    text-transform: uppercase;
`;

export const ParagraphType = css`
    font-size: 1.8rem;
    font-style: normal;
    line-height: 1.8em;
    letter-spacing: 0.01em;
    font-weight: 500;
`;

export const ParagraphFont = styled.span`
    font-size: 1.8rem;
    font-style: normal;
    line-height: 1.8em;
    letter-spacing: 0.01em;
    font-weight: 500;
`;

export const ParagraphFont2 = css`
    font-size: 1.8rem;
    font-style: normal;
    line-height: 1.8em;
    letter-spacing: 0.01em;
    font-weight: 500;
`;

export const MyHeadline12 = css`
    font-style: normal;
    line-height: 1.3em;
    letter-spacing: 0.005em;
    font-weight: 600;
    font-size: 2.8rem;
`;

export const Headline4Type = css`
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 1.2em;
    font-style: normal;
    letter-spacing: 0.03em;
`;

export const HeadlineSubPage = css`
    font-weight: 600;
    font-size: 6.2rem;
    line-height: 1.2em;
    font-style: normal;
    letter-spacing: 0.03em;
`;

export const Orange = css`
    color: #f2981a;
`;

export const InfoGraphicSmallHeadline = css`
    font-size: 2rem;
    font-style: normal;
    letter-spacing: 0.02em;
    font-weight: 600;
`;

export const InfoGraphicFont = css`
    font-style: normal;
    letter-spacing: 0.02em;
    font-weight: 700;
    font-style: normal;
    font-size: 3rem;
`;

export const TopLineFont = css`
    font-size: 1.4rem;
    font-style: normal;
    line-height: 1em;
    letter-spacing: 0.01em;
    font-weight: 600;
    text-transform: uppercase;
`;
export const Head1Font = css`
    margin-top: 2rem;
    font-style: normal;
    line-height: 1.3em;
    letter-spacing: 0.005em;
    font-weight: 600;
    font-size: 2.8rem;

    color: $textLight;
`;

export const ParaFont = css`
    color: $textLight;
    font-size: 1.8rem;
    font-style: normal;
    line-height: 1.8em;
    letter-spacing: 0.01em;
    font-weight: 500;
`;
