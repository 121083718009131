import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'

import { SubHeadlineFont } from '../../style/fonts'

export const BaseSvg = styled('svg')`
    width: 30px;
    height: 30px;
`

export default styled('header')`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    background-color: ${props =>
        !props.sticky ? 'transparent' : 'rgba(255, 255, 255, 0.9)'};

    transform: ${props =>
        !props.sticky ? 'translate3d(0,0,0)' : 'translate3d(0,-20%, 0)'};
`

export const HeaderWrapper = styled('header')`
    height: 8.5rem;
    width: 90%;
    max-width: 1280px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
`

export const MobileNavWrapper = styled('nav')`
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding-bottom: 2.5rem;
    margin-bottom: -1.5rem;

    > div {
        padding: 0 1rem;
        > a {
            ${SubHeadlineFont};
            font-size: 1.6rem;
            font-weight: 300;
            color: #333;
            text-decoration: none;

            padding: 0.075em 0.4em;
        }
    }

    /*

	::-webkit-scrollbar {
	    width: 14px;
	    height: 18px;
	}
	::-webkit-scrollbar-thumb {
	    height: 6px;
	    border: 4px solid rgba(0, 0, 0, 0);
	    background-clip: padding-box;
	    -webkit-border-radius: 7px;
	    background-color: rgba(0, 0, 0, 0.15);
	    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
	}
	::-webkit-scrollbar-button {
	    width: 0;
	    height: 0;
	    display: none;
	}
	::-webkit-scrollbar-corner {
	    background-color: transparent;
	}

	.scroll {overflow:auto;}
	.scroll::-webkit-scrollbar {
	    width:16px;
	    height:16px;
	    background:inherit;
	}
	.scroll::-webkit-scrollbar-track:vertical {
	    border-right:8px solid rgba(0,0,0,.2);
	}
	.scroll::-webkit-scrollbar-thumb:vertical {
	    border-right:8px solid rgba(255,255,255,.2);
	}
	.scroll::-webkit-scrollbar-track:horizontal {
	    border-bottom:8px solid rgba(0,0,0,.2);
	}
	.scroll::-webkit-scrollbar-thumb:horizontal {
	    border-bottom:8px solid rgba(255,255,255,.2);
	}
	.scroll::-webkit-scrollbar-corner,
	    .scroll::-webkit-resizer {background:inherit;
	    border-right:8px solid rgba(255,255,255,.2); //optional
	    border-bottom:8px solid rgba(255,255,255,.2); //optional
	}

	*/
`

export const DesktopNavigation = styled('nav')`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > a {
        text-decoration: none;
        color: ${props => (props.inverted ? '#333' : '#fff')};
        transition: color 0.5s ease-in-out;
        font-size: 1.7rem;
        font-weight: 600;
        margin: 0 0.4em;
        margin: 0 1.25vw;

        font-size: 14px;
        line-height: 3.14286;
        font-weight: 600;
        letter-spacing: -0.01em;

        @media (min-width: 1280px) {
            margin: 0 1em;
        }
    }
`

export const DesktopHeader = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    transition: transform 0.3s ease-in-out;
    transform: ${props =>
        !props.minimize ? 'translate3d(0,0,0)' : 'translate3d(0,25%,0)'};
`

export const FlexRight = styled('div')`
    display: flex;
`

export const Button = styled('button')`
    outline: none;
    z-index: 2000;
    //-webkit-tap-highlight-color: rgba(14, 83, 167, 0.2);
    &:focus,
    &:active {
    }
`

export const Line = styled('line')`
    stroke: ${props =>
        !props.white ? 'rgba(14, 83, 167, 1)' : 'rgba(255, 255, 255, 1)'};
`

export const LogoWrapper = styled('h1')`
    padding: 0;
    margin: 0;
    color: rgba(14, 83, 167, 1);
    color: ${props => (props.inverted ? '#333' : '#fff')};
    transition: color 0.5s ease-in-out;
    padding: 0;

    letter-spacing: 0.02em;
    font-weight: 700;
    text-decoration: none;

    transition: transform 0.3s ease-in-out;
    transform: ${props =>
        !props.minimize
            ? 'scale3d(1,1,1) translate3d(0,0,0)'
            : 'scale3d(0.8,0.8,1) translate3d(0,25%,0)'};
`

export const LogoText = styled('span')`
    display: block;
    font-family: sans-serif;

    font-size: 2.3rem;
    line-height: 0.85em;

    @media screen and (min-width: 1024px) {
        font-size: 2.8rem;
    }

    ${props =>
        props.second &&
        css`
            margin-left: 0.6em;
        `};
`

/*
const MenuOverlay = styled<{ isOpened: boolean }, 'nav'>('nav')`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eee;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;

    z-index: 1000;

    ${props =>
        props.isOpened &&
        css`
            opacity: 1;
            transition-delay: 0.225s;
            pointer-events: all;
        `};

`;
*/

const rotate360 = keyframes`
	0% {
			font-size: 1.4rem;
			opacity: 1;
			bottom: 0.4em;
	}

	50% {
			font-size: 1.4rem;
			opacity: 0;
			bottom: 0.4em;
	}

	51% {
			font-size: 1.1rem;
			opacity: 0;
			bottom: 2em;
	}

	to {
			font-size: 1.1rem;
			opacity: 1;
			bottom: 2em;
	}
`

export const InputWrapper = styled('div')`
	position: relative;
	font-size: 1.4rem;
	line-height: 1.2em;

	margin: 0 0 0 0.4em;
	margin: 0 0 0 2vw;

	@media (min-width: 1280px) {
		margin: 0 0 0 1.5em;
	}

	> input {
        background: none;
        line-height: 22px !important;
		margin: 0 !important;

		border-bottom: ${props =>
        props.inverted ? '1px solid #333' : '1px solid #fff'};
    transition: border 0.5s ease-in-out;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-radius: 0;
		outline-offset: 0;
		outline: none;
		width: 8rem;
	}

	> label {
		position: absolute;
		bottom: 0.4em;
		left: 0em;
		line-height: 1em !important;
		display: block !important;
		pointer-events: none;
		user-select: none;

		transition: ${props =>
        props.focused ? 'color 0.3s ease-in-out' : 'color 0.3s ease-in-out'};

    transition: color 0.5s ease-in-out;
    color: ${props => (props.inverted ? '#333' : '#fff')};

		animation: ${props =>
        props.focused ? '0.3s ' + rotate360 + ' ease-in-out forwards' : 'unset'};
	}


	> span {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;

		display: flex;
		justify-content: center;
		align-items: center;


		> span {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: flex-start;

				button {
				}
				svg {

					width: 0.875em;
					height: 0.875em;

					path {
						transition: ${props => (props.focused ? 'fill 0.3s ease-in-out' : '')};
						fill: ${props => (props.focused ? '#fff' : '#fff')};
					}
				}
			}
		}
	}
`
