import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Match } from '@reach/router'
import Header from './Header/index'
import Footer from './Footer'
import './layout.css'

import { Box } from '@rebass/grid/emotion'
// import { jsx } from '@emotion/react'
// import styled from '@emotion/styled'
import { ThemeProvider } from '@emotion/react'

const theme = {
    breakpoints: ['32em', '56em', '74em'],
}

import SearchResults from './searchResults'
export default class Layout extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            leaveHeaderSearch: false,
            search: null
        }
    }

    onLeaveHeaderSearch = () => {
        this.setState({
            leaveHeaderSearch: true
        })
    }

    onSearchChange = (search) => {
        this.setState({
            search,
            leaveHeaderSearch: false
        })
    }

    render() {
        const { search } = this.state
        const { children, title, metaDescription } = this.props

        return (
            <StaticQuery
                query={query}
                render={data => (
                    <ThemeProvider theme={theme}>
                        <Box pt={85}>
                            <Helmet
                                title={title ? `${title} – ${data.site.siteMetadata.title}` : `${data.site.siteMetadata.title}`}
                                meta={[
                                    { name: 'description', content: metaDescription },
                                ]}
                            >
                                <meta charSet="utf-8" />
                                <meta name="theme-color" content="#4975ba"/>
                                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0"></meta>
                                <html lang="en" />
                            </Helmet>

                            <Match path="/">
                                {() => <Header inverted={true} leaveHeaderSearch={this.state.leaveHeaderSearch} onSearchChange={this.onSearchChange} siteTitle={data.site.siteMetadata.title} />}
                            </Match>
                                {!search && children}
                                <SearchResults onLeaveHeaderSearch={this.onLeaveHeaderSearch} onSearchChange={this.onSearchChange} term={search} />
                            <Footer />
                        </Box>
                    </ThemeProvider>
                )}
            />
        )

    }
}


const query = graphql`
    query SiteTitleQuery {
                            site {
                        siteMetadata {
                            title
                        }
                        }
                    }
`