import React from 'react'

import { Headline, Text, WrappedContent } from './style'
import { css } from '@emotion/react'
import { Flex, Box } from '@rebass/grid/emotion'
import Link from 'gatsby-link'
const MenuLink = css`
    text-decoration: none;
`

const WhatsApp = css`
    position: fixed;
    width: 52px;
    height: 52px;
    bottom: 15px;
    right: 15px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 28px;
    z-index: 100;

    @media screen and (min-width: 700px) {
        display: none;
    }
`

export default () =>
    <WrappedContent color="dark">
        <div>
            <Flex flexDirection={["column", "row"]} justifyContent="space-between">
                <Box>
                    <Headline dark={true}>© Balkon Systeme Taucha GmbH</Headline>
                    <Link css={MenuLink} to="/leistungen/"><Text dark={true}>Leistungen</Text></Link>
                    <Link css={MenuLink} to="/ausstattung/"><Text dark={true}>Ausstattung</Text></Link>
                    <Link css={MenuLink} to="/referenzen/"><Text dark={true}>Referenzen</Text></Link>
                    <Link css={MenuLink} to="/unternehmen/"><Text dark={true}>Unternehmen</Text></Link>
                    <Link css={MenuLink} to="/kontakt/"><Text dark={true}>Kontakt</Text></Link>

                    <br />
                    <Link css={MenuLink} to="/impressum/"><Text dark={true}>Impressum</Text></Link>
                    <Link css={MenuLink} to="/datenschutz/"><Text dark={true}>Datenschutz</Text></Link>
                </Box>
                <Box mt={[50, 0]}>
                    <Headline dark={true}>Kontakt:</Headline>
                    <Text dark={true}>
                        Telefon: <a onClick={() => {
                            if (typeof window !== "undefined" && window.dataLayer) {
                                window.dataLayer.push({
                                    'event': 'contactPhone',
                                    'category': 'contactCategory',
                                    'action': 'call',
                                });
                            }
                            else {
                                console.error("data layer not found")
                            }
                        }} href="tel:493429898890">+49 (0)34298-9889-0</a><br />
                        e-mail: <a onClick={() => {
                            if (typeof window !== "undefined" && window.dataLayer) {
                                window.dataLayer.push({
                                    'event': 'contactEmail',
                                    'category': 'contactCategory',
                                    'action': 'email',
                                });
                            }
                            else {
                                console.error("data layer not found")
                            }
                        }} href="mailto:info@balkonsysteme.info">info@balkonsysteme.info</a><br />
                    </Text>
                    <br />
                    <br />
                    <Headline dark={true}>Adresse:</Headline>
                    <Text dark={true}>
                        Schillerstraße 86<br />
                        04425 Taucha
                    </Text>
                    <Box mt={20}>
                        <a href="https://instagram.com/balkonsysteme?igshid=YzAwZjE1ZTI0Zg==" target="_blank" rel="noreferrer">
                            <span css={{
                                display: 'block',
                                width: '40px',
                                height: '40px',
                            }}>
                                <SvgInstagram />
                            </span>
                        </a>
                    </Box>
                </Box>
            </Flex>
            <Link onClick={() => {
                if (typeof window !== "undefined" && window.dataLayer) {
                    window.dataLayer.push({
                        'event': 'contactWhatsApp',
                        'category': 'contactCategory',
                        'action': 'click',
                    });
                }
                else {
                    console.error("data layer not found")
                }
            }} css={WhatsApp} rel="noreferrer" href="https://wa.me/493429898890" className="float" target="_blank">
                <span css={{
                    display: 'block',
                    width: '32px',
                    height: '32px',
                    marginTop: '10px',
                    marginLeft: '10px',
                }}>
                    <SvgWhatsApp />
                </span>
            </Link>
        </div>
    </WrappedContent>


const SvgWhatsApp = () =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.22 175.55">
        <defs>
            <linearGradient id="b" x1="85.92" x2="86.53" y1="32.57" y2="137.09" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#57d163" />
                <stop offset="1" stopColor="#23b33a" />
            </linearGradient>
            <filter id="a" width="1.11" height="1.11" x="-.06" y="-.06" colorInterpolationFilters="sRGB">
                <feGaussianBlur stdDeviation="3.53" />
            </filter>
        </defs>
        <path fill="#b3b3b3" d="m54.53 138.45 2.24 1.32a61.06 61.06 0 0 0 31.12 8.53h.03a61.23 61.23 0 0 0 61.15-61.14c0-16.33-6.35-31.7-17.9-43.25a60.75 60.75 0 0 0-43.23-17.93 61.23 61.23 0 0 0-61.18 61.13 60.98 60.98 0 0 0 9.35 32.54l1.46 2.31-6.18 22.56zm-40.8 23.54 10.43-38.11a73.42 73.42 0 0 1-9.82-36.77c.02-40.56 33.02-73.55 73.58-73.55 19.68 0 38.15 7.67 52.04 21.57s21.54 32.38 21.53 52.04c-.01 40.55-33.02 73.55-73.57 73.55h-.04a73.54 73.54 0 0 1-35.15-8.95zm0 0" filter="url(#a)" />
        <path fill="#fff" d="m12.97 161.24 10.43-38.12a73.42 73.42 0 0 1-9.82-36.77C13.6 45.8 46.61 12.8 87.16 12.8c19.68.01 38.16 7.67 52.05 21.57s21.54 32.39 21.53 52.04c-.02 40.55-33.03 73.55-73.58 73.55h-.03a73.54 73.54 0 0 1-35.16-8.95z" />
        <path fill="url(#linearGradient1780)" d="M87.18 25.23a61.23 61.23 0 0 0-61.17 61.13 60.98 60.98 0 0 0 9.34 32.53l1.46 2.31-6.18 22.56 23.15-6.07 2.23 1.33a61.07 61.07 0 0 0 31.13 8.52h.02a61.23 61.23 0 0 0 61.15-61.13 60.75 60.75 0 0 0-17.9-43.25 60.75 60.75 0 0 0-43.23-17.93z" />
        <path fill="url(#b)" d="M87.18 25.23a61.23 61.23 0 0 0-61.17 61.13 60.98 60.98 0 0 0 9.34 32.53l1.46 2.31-6.18 22.56 23.15-6.07 2.23 1.33a61.07 61.07 0 0 0 31.13 8.52h.02a61.23 61.23 0 0 0 61.15-61.13 60.75 60.75 0 0 0-17.9-43.25 60.75 60.75 0 0 0-43.23-17.93z" />
        <path fill="#fff" fillRule="evenodd" d="M68.77 55.6c-1.38-3.06-2.83-3.12-4.13-3.17l-3.53-.05c-1.22 0-3.22.46-4.9 2.3s-6.44 6.3-6.44 15.34 6.6 17.78 7.51 19 12.72 20.39 31.4 27.76c15.53 6.12 18.7 4.9 22.07 4.6s10.87-4.45 12.4-8.74 1.54-7.97 1.08-8.74-1.69-1.23-3.53-2.15-10.88-5.36-12.56-5.98-2.91-.92-4.14.92-4.74 5.98-5.82 7.2-2.14 1.39-3.98.47-7.76-2.86-14.78-9.12c-5.47-4.88-9.16-10.9-10.23-12.73s-.12-2.84.8-3.75c.83-.83 1.84-2.15 2.77-3.22s1.22-1.84 1.83-3.07.3-2.3-.15-3.22-4.03-10.01-5.67-13.65" />
    </svg>


const SvgInstagram = () =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" >
        <g fill="#FFF" strokeMiterlimit="10" strokeWidth="0" transform="matrix(2.81 0 0 2.81 1.4 1.4)">
            <path d="M62.26 90H27.74A27.77 27.77 0 0 1 0 62.26V27.74A27.77 27.77 0 0 1 27.74 0h34.52A27.77 27.77 0 0 1 90 27.74v34.52A27.77 27.77 0 0 1 62.26 90zM27.74 7A20.76 20.76 0 0 0 7 27.74v34.52C7 73.7 16.3 83 27.74 83h34.52C73.7 83 83 73.7 83 62.26V27.74C83 16.3 73.7 7 62.26 7H27.74z" />
            <path d="M45.26 70.47c-13.9 0-25.22-11.31-25.22-25.21s11.31-25.22 25.22-25.22 25.21 11.31 25.21 25.21-11.31 25.22-25.21 25.22zm0-43.43a18.24 18.24 0 0 0-18.22 18.21 18.24 18.24 0 0 0 18.22 18.22 18.24 18.24 0 0 0 18.21-18.21 18.23 18.23 0 0 0-18.21-18.22z" />
            <circle cx="70.56" cy="19.94" r="4.9" />
        </g>
    </svg >