import React from 'react';

import { css } from '@emotion/react'
import styled from '@emotion/styled'

const LogoWrapper = styled('h1')`
    padding: 0;
    margin: 0;
    color: rgba(14, 83, 167, 1);
    color: ${props => (props.inverted ? '#333' : '#fff')};
    transition: color 0.5s ease-in-out;
    padding: 0;

    letter-spacing: 0.02em;
    font-weight: 700;
    text-decoration: none;

    transition: transform 0.3s ease-in-out;
    transform: ${props =>
        !props.minimize
            ? 'scale3d(1,1,1) translate3d(0,0,0)'
            : 'scale3d(0.8,0.8,1) translate3d(0,25%,0)'};
`

const LogoText = styled('span')`
    display: block;
    font-family: sans-serif;

    font-size: 2.3rem;
    line-height: 0.85em;

    @media screen and (min-width: 1024px) {
        font-size: 3.2rem;
    }

    ${props =>
        props.second &&
        css`
            margin-left: 0.6em;
        `};
`

export default ({
    inverted,
    minimize
}) => (
    <LogoWrapper inverted={inverted} minimize={minimize}>
        <LogoText>Balkon</LogoText>
        <LogoText second={true}>Systeme</LogoText>
    </LogoWrapper>
);
