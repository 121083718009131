import React, { Component } from 'react'
import { StaticQuery, graphql } from "gatsby"
import Referenzen from '../referenzen';

import Fuse from 'fuse.js'
class SearchResults extends Component {

    componentDidMount() {
        const list = this.props.data.allProject.edges.map(item => item.node)

        var options = {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [{
                name: 'titelLagebeschreibung',
                weight: 0.25,
            }, {
                name: 'beschreibung',
                weight: 0.25,
            }, {
                name: 'konstruktionsVariante',
                weight: 0.25,
            }, {
                name: 'sonderkonstruktion',
                weight: 0.25,
            }, {
                name: 'gelander',
                weight: 0.25,
            }, {
                name: 'beschichtung',
                weight: 0.25,
            }, {
                name: 'entwasserungebene',
                weight: 0.25,
            }, {
                name: 'projektgrosse',
                weight: 0.25,
            }, {
                name: 'ort',
                weight: 0.25,
            }, {
                name: 'projekt',
                weight: 1,
            }]
        };

        this.fuse = new Fuse(list, options); // "list" is the item array
    }

    render() {
        const { term } = this.props

        if (!term) {
            return null
        }
        /*false && console.warn(term, data.allProject.edges.filter(item => item.node.projekt.indexOf(term) !== -1)) */

        return (
            <Referenzen
                onLeaveHeaderSearch={this.props.onLeaveHeaderSearch}
                onSearchChange={this.props.onSearchChange} term={term} search={true} data={{
                    allProject: {
                        edges: this.fuse.search(term).map(item => ({ node: item.item }))
                    }
                }} />
        )
    }
}

export default ({ term, ...props }) => (
    <StaticQuery
        query={graphql`
    query {
        allProject {
            edges {
            node {
                id
                titelLagebeschreibung
                beschreibung
                slug
                path
                projekt
                teaser {
                    projekt
                    file {
                        childImageSharp {
                          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                        }
                    }
                }
            }
            }
        }
    }
    `}
        render={(data) => (
            <SearchResults
                onLeaveHeaderSearch={props.onLeaveHeaderSearch}
                onSearchChange={props.onSearchChange}
                data={data}
                term={term} />
        )}
    />
)