import styled from '@emotion/styled'

const COLORS = {
    dark: '#2e282a',
}

export const WrappedContent = styled('footer')`
    display: block;
    background-color: ${({ color }) => COLORS[color]};

    > div {
        width: 95%;
        max-width: 1250px;
        margin: 0 auto;
        padding: 6rem 0 4rem 0;
    }
`

export const Headline = styled('h1')`
    color: ${({ dark }) => (dark ? '#ffffff' : '#000000')};
    font-size: 2.2rem;
    font-weight: bold;
    margin: 0 0 2rem 0;
    padding: 0;
`

export const Text = styled('h2')`
    color: ${({ dark }) => (dark ? '#ffffff' : '#000000')};
    font-size: 1.6rem;
    font-weight: normal;

    a {
        text-decoration: none;
        color: ${({ dark }) => (dark ? '#ffffff' : '#000000')};
    }
`
